<template>
  <v-container>
    <v-row>
      <h1 class="white--text text-center">Товары</h1>
    </v-row>
    <div class="text-center mt-8">
      <v-pagination
          v-model="page"
          :length="pages"
          :total-visible="10"
          @click.native="changePage()"
      ></v-pagination>
    </div>
    <v-row
        justify="start"
        class="text-center">
      <v-col
          xl="2"
          lg="3"
          md="4"
          v-for="item in items"
          :key="item._id"
          :img-height="'200'"
          :max-width="'310'"
      >
        <Card
            class="mx-auto"
            :item="item"
            :img-height="'200'"
            :max-width="'310'"
        />
      </v-col>
    </v-row>
    <div class="text-center mb-8">
      <v-pagination
          v-model="page"
          :length="pages"
          :total-visible="10"
          @click.native="changePage()"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
  import axios from "axios";
  import Card from "../components/Card";

  export default {
    name: 'ListItems',

    components: {
      Card
    },

    data () {
      return {
        items: [],
        limit: 12,
        page: 1,
        total: 0,
      }
    },

    computed: {
      pages: function() {
        if (this.total % this.limit === 0) {
          return this.total / this.limit
        } else {
          return (this.total - this.total % this.limit) / this.limit + 1
        }
      }
    },

    methods: {
      changePage() {
          this.fetchData()
      },
      async fetchData() {
        try {
          const url = `https://testded.na4u.ru/cockpit-master/api/collections/get/items?limit=${this.limit}&skip=${(this.page - 1) * this.limit}&token=8553d888d64e6b0ead3bc23a4a028b`
          const response = await axios.get(url)
          const results = response.data.entries
          this.total = response.data.total
          console.log(response.data.total)
          this.items = results.map(post => ({
            title: post.title,
            _id: post._id,
            code: post.code,
            manufacturer: post.manufacturer,
            breadcrumbs: post.breadcrumbs,
            price: post.price,
            description: post.description,
            gif: post.gif,
            properties: post.properties,
            photos: post.photos,
            options: post.options,
            countProduct: 1,
            show: false
          }))
        } catch (err) {
          if (err.response) {
            console.log("Server Error:", err)
          } else if (err.request) {
            console.log("Network Error:", err)
          } else {
            console.log("Client Error:", err)
          }
        }
      },
    },

    mounted() {
      this.fetchData()
    },
  }
</script>

<style>

</style>

