<template>
  <list-items />
</template>

<script>
import ListItems from '../components/ListItems'

export default {
  name: 'Shop',

  components: {
    ListItems,
  },
}
</script>
